html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  color: #70706f; /* default color */
}

* {
  font-family: 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
}

*:focus {
  outline: 0 !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d3d2d2;
  height: 20px;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

@font-face {
  font-family: 'fontAwesome';
  src: url('assets/fonts/fontAwesome/fontawesome-webfont.eot?zh4jvp');
  src: url('assets/fonts/fontAwesome/fontawesome-webfont.eot?#iefixzh4jvp')
      format('embedded-opentype'),
    url('assets/fonts/fontAwesome/fontawesome-webfont.woff?zh4jvp')
      format('woff'),
    url('assets/fonts/fontAwesome/fontawesome-webfont.ttf?zh4jvp')
      format('truetype'),
    url('assets/fonts/fontAwesome/fontawesome-webfont.svg?zh4jvp#fontawesomeregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'fontAwesomeRegular';
  src: url('assets/fonts/fontAwesome/fa-regular-400.eot?zh4jvp');
  src: url('assets/fonts/fontAwesome/fa-regular-400.eot?#iefixzh4jvp')
      format('embedded-opentype'),
    url('assets/fonts/fontAwesome/fa-regular-400.woff?zh4jvp') format('woff'),
    url('assets/fonts/fontAwesome/fa-regular-400.ttf?zh4jvp') format('truetype'),
    url('assets/fonts/fontAwesome/fa-regular-400.svg?zh4jvp#fontawesomeregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'icomoon';
  src: url('assets/fonts/icomoon/icomoon.eot?zh4jvp');
  src: url('assets/fonts/icomoon/icomoon.eot?#iefixzh4jvp')
      format('embedded-opentype'),
    url('assets/fonts/icomoon/icomoon.woff?zh4jvp') format('woff'),
    url('assets/fonts/icomoon/icomoon.ttf?zh4jvp') format('truetype'),
    url('assets/fonts/icomoon/icomoon.svg?zh4jvp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* input filter cars */
input[id^='react-select-2-input'] {
  opacity: 1 !important;
  width: 170px !important;
}

div.btnLoginBox span {
  margin: 0 auto;
  line-height: 1;
  padding-right: 0 !important;
}
