@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  color: #70706f; /* default color */
}

* {
  font-family: 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
}

*:focus {
  outline: 0 !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d3d2d2;
  height: 20px;
}

@font-face {
  font-family: 'fontAwesome';
  src: url(/static/media/fontawesome-webfont.00bb62bc.eot);
  src: url(/static/media/fontawesome-webfont.00bb62bc.eot?#iefixzh4jvp)
      format('embedded-opentype'),
    url(/static/media/fontawesome-webfont.4bfbf7eb.woff)
      format('woff'),
    url(/static/media/fontawesome-webfont.260d033f.ttf)
      format('truetype'),
    url(/static/media/fontawesome-webfont.2306f642.svg#fontawesomeregular)
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'fontAwesomeRegular';
  src: url(/static/media/fa-regular-400.0232fe8b.eot);
  src: url(/static/media/fa-regular-400.0232fe8b.eot?#iefixzh4jvp)
      format('embedded-opentype'),
    url(/static/media/fa-regular-400.3cace4a0.woff) format('woff'),
    url(/static/media/fa-regular-400.398be22b.ttf) format('truetype'),
    url(/static/media/fa-regular-400.d0fdc7c2.svg#fontawesomeregular)
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.c406a7aa.eot);
  src: url(/static/media/icomoon.c406a7aa.eot?#iefixzh4jvp)
      format('embedded-opentype'),
    url(/static/media/icomoon.1d93ca7d.woff) format('woff'),
    url(/static/media/icomoon.f4cee126.ttf) format('truetype'),
    url(/static/media/icomoon.813582ed.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

/* input filter cars */
input[id^='react-select-2-input'] {
  opacity: 1 !important;
  width: 170px !important;
}

div.btnLoginBox span {
  margin: 0 auto;
  line-height: 1;
  padding-right: 0 !important;
}

